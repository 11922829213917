var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"SuporteDuvidasForm"},[_c('b-form',{attrs:{"id":"SuporteDuvidasForm"}},[_c('b-row',{staticClass:"text-left",class:_vm.FormularioDeDuvida == true ? 'd-none' : ''},[_c('b-col',{attrs:{"md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Nome *","label-for":"nome"}},[_c('validation-provider',{attrs:{"name":"Seu nome completo","rules":_vm.validacoes.nome},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nome","placeholder":"Seu nome completo","state":errors.length > 0 ? false : null},model:{value:(_vm.nome),callback:function ($$v) {_vm.nome=$$v},expression:"nome"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label":"E-mail *","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Seu e-mail","rules":_vm.validacoes.email},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","placeholder":"Seu e-mail","state":errors.length > 0 ? false : null},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Telefone","label-for":"telefone"}},[_c('b-form-input',{attrs:{"id":"telefone","placeholder":"Seu e-mail"},model:{value:(_vm.telefone),callback:function ($$v) {_vm.telefone=$$v},expression:"telefone"}})],1)],1),_c('b-col',{attrs:{"md":"6","xl":"6"}},[_c('b-form-group',{attrs:{"label":"Empresa","label-for":"empresa"}},[_c('b-form-input',{attrs:{"id":"empresa","placeholder":"Nome da empresa"},model:{value:(_vm.empresa),callback:function ($$v) {_vm.empresa=$$v},expression:"empresa"}})],1)],1)],1),_c('b-row',{staticClass:"text-left"},[_c('b-col',{staticClass:"col-10 mx-auto"},[_c('b-form-group',{attrs:{"label":"Assunto *","label-for":"assunto"}},[_c('validation-provider',{attrs:{"name":"Assunto","rules":_vm.validacoes.assunto},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"assunto","placeholder":_vm.placeholder.assunto,"state":errors.length > 0 ? false : null},model:{value:(_vm.assunto),callback:function ($$v) {_vm.assunto=$$v},expression:"assunto"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"col-10 mx-auto"},[_c('b-form-group',{attrs:{"label":"Mensagem *","label-for":"mensagem"}},[_c('validation-provider',{attrs:{"name":"Assunto","rules":_vm.validacoes.mensagem},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"mensagem","placeholder":_vm.placeholder.mensagem,"state":errors.length > 0 ? false : null},model:{value:(_vm.mensagem),callback:function ($$v) {_vm.mensagem=$$v},expression:"mensagem"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"col-12 mb-1 text-center"},[_c('b-button',{attrs:{"type":"submit","variant":"custom","disabled":_vm.bloquearEnvio},on:{"click":function($event){$event.preventDefault();return _vm.enviarFormulario.apply(null, arguments)}}},[_vm._v(" Enviar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }